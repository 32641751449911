<template>
  <DatePickerRoot
    id="date-field"
    v-model="selectDate"
    v-model:open="showDatePopup"
    :max-value="maxValue"
    :placeholder="parseDate(dayjs().format('YYYY-MM-DD'))"
    :min-value="parseDate(dayjs().format('YYYY-MM-DD'))"
    :number-of-months="2"
  >
    <DatePickerField>
      <DatePickerTrigger>
        <slot :format-date="formatDate">
          <!-- 默认内容 -->
        </slot>
      </DatePickerTrigger>
    </DatePickerField>

    <DatePickerContent
      align="start"
      :side-offset="18"
      style="z-index: 10"
      class="PopoverContent"
    >
      <DatePickerArrow class="fill-white" />
      <DatePickerCalendar
        v-slot="{ weekDays, grid }"
        class="rounded-2xl bg-white shadow-[0_1px_30px_0px_rgba(113,113,113,.35)]"
        p="y-8 x-6"
        weekday-format="short"
      >
        <div
          class="flex space-x-10"
        >
          <DatePickerGrid
            v-for="(month, index) in grid"
            :key="month.value.toString()"
            class="w-full border-collapse select-none space-y-1"
          >
            <DatePickerGridHead>
              <div flex="~ items-center justify-center" h="9" class="relative" m="b-5 l-2">
                <DatePickerPrev
                  v-if="index === 0"
                  class="absolute left-0 h-9 w-9 rounded-md data-[disabled]:cursor-not-allowed hover:bg-[#121212] data-[disabled]:text-black/30 hover:text-white data-[disabled]:hover:bg-transparent data-[disabled]:hover:text-black/30"
                  cursor="pointer"
                >
                  <div class="i-local-arrow_right align-middle" rotate="180" text="2xl"></div>
                </DatePickerPrev>
                <div text="#121212 xl" font="600" m="x-1">
                  {{ dayjs(month.value).format('MMM YYYY') }}
                </div>
                <DatePickerNext
                  v-if="index === 1"
                  class="absolute right-0 h-9 w-9 rounded-md data-[disabled]:cursor-not-allowed hover:bg-[#121212] data-[disabled]:text-black/30 hover:text-white data-[disabled]:hover:bg-transparent data-[disabled]:hover:text-black/30"
                  cursor="pointer"
                >
                  <div class="i-local-arrow_right align-middle" text="2xl"></div>
                </DatePickerNext>
              </div>
              <DatePickerGridRow class="mb-1 w-full flex justify-between">
                <DatePickerHeadCell
                  v-for="day in weekDays"
                  :key="day"
                  font="400"
                  m="b-2"
                  class="w-11 rounded-md text-121212 text-sm"
                >
                  {{ day }}
                </DatePickerHeadCell>
              </DatePickerGridRow>
            </DatePickerGridHead>
            <DatePickerGridBody>
              <DatePickerGridRow
                v-for="(weekDates, i) in month.rows"
                :key="`weekDate-${i}`"
                class="w-full flex"
              >
                <DatePickerCell
                  v-for="weekDate in weekDates"
                  :key="weekDate.toString()"
                  :date="weekDate"
                >
                  <DatePickerCellTrigger
                    :day="weekDate"
                    :month="month.value"
                    h="11"
                    w="11"
                    flex="~ items-center justify-center"
                    b="~ transparent"
                    text="base 121212"
                    cursor="pointer"
                    m="y-0.5"
                    whitespace="nowrap"
                    rounded="full"
                    bg="transparent"
                    class="outline-none data-[unavailable]:pointer-events-none data-[outside-view]:invisible before:absolute before:top-[5px] before:hidden before:h-1 before:w-1 data-[disabled]:cursor-not-allowed hover:border-black before:rounded-full before:bg-white data-[selected]:bg-black hover:bg-black data-[disabled]:text-black/30 data-[selected]:text-white data-[unavailable]:text-black/30 hover:text-white data-[selected]:font-medium data-[unavailable]:line-through data-[today]:before:block data-[disabled]:hover:bg-transparent data-[selected]:before:bg-white data-[disabled]:hover:text-black/30"
                  />
                </DatePickerCell>
              </DatePickerGridRow>
            </DatePickerGridBody>
          </DatePickerGrid>
        </div>
      </DatePickerCalendar>
    </DatePickerContent>
  </DatePickerRoot>
</template>

<script lang="ts" setup>
import type { CalendarDate, DateValue } from '@internationalized/date'
import { parseDate } from '@internationalized/date'
import { toDate } from 'radix-vue/date'
import { ENUM_EventType, ENUM_PageName } from '~/enums'
import type { SearchDateClicksClickEvent } from '~/types/event-tracking'

const props = withDefaults(defineProps<Props>(), {
  formatter: 'DD MMM, YYYY',
  isDetail: false,
  attrName: '',
})
const gtm = useGtm()
const dayjs = useDayjs()
const { query } = useRoute()

interface Props {
  formatter?: string
  isDetail?: boolean
  pageType?: string
  attrName?: string
}

const filterStore = useFilterConditionsStore()
const { locale } = useLocale()
const { showDatePopup } = useUseHomePopup()

if (filterStore.filterDateRef.get(props.isDetail) && dayjs().startOf('day').isAfter(filterStore.filterDateRef.get(props.isDetail))) {
  filterStore.filterDateRef.set(props.isDetail, dayjs().startOf('day').valueOf())
}

const selectDate = computed({
  set(value: DateValue) {
    filterStore.filterDateRef.set(props.isDetail, toDate(value).getTime())
    showDatePopup.value = false
    trackSearchDateClick()
  },
  get() {
    if (!filterStore.filterDateRef.get(props.isDetail)) {
      return '' as unknown as CalendarDate
    }
    const date = filterStore.filterDateRef.get(props.isDetail) || dayjs().valueOf()
    return parseDate(dayjs(Number(date)).format('YYYY-MM-DD'))
  },
})

const maxValue = computed(() => {
  return parseDate(dayjs().add(3, 'month').format('YYYY-MM-DD'))
})

const formatDate = computed(() => {
  return filterStore.filterDateRef.get(props.isDetail) ? dayjs(filterStore.filterDateRef.get(props.isDetail)).format(props.formatter) : ''
})

function trackSearchDateClick() {
  const eventParams: SearchDateClicksClickEvent = {
    event: ENUM_EventType.SearchDateClick,
    page_category: props.isDetail ? ENUM_PageName.Detail : (props.pageType === 'home' ? ENUM_PageName.Home : ENUM_PageName.List),
    city: filterStore.filterConditions.selectedCity?.destinationName,
    city_id: filterStore.filterConditions.selectedCity?.destinationId,
    country_code: filterStore.filterConditions.selectedCity?.countryCode,
    search_date: dayjs(filterStore.filterConditions.date).format('YYYY-MM-DD'),
    locale: locale.value,
    campaign_id: query.campaign_id as string ?? '',
    campaign_name: query.campaign_name as string ?? '',
    attraction_id: props.isDetail ? query.attrId as string : '',
    attraction_name: props.isDetail ? props.attrName ?? '' : '',
  }
  gtm?.trackEvent(eventParams)
}
</script>

<style>
.PopoverContent {
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
}
.PopoverContent[data-side="top"] {
  animation-name: slideUp;
}
.PopoverContent[data-side="bottom"] {
  animation-name: slideDown;
}
</style>
